// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-about-nordic-asia-jsx": () => import("./../../../src/templates/About/AboutNordicAsia.jsx" /* webpackChunkName: "component---src-templates-about-about-nordic-asia-jsx" */),
  "component---src-templates-about-team-jsx": () => import("./../../../src/templates/About/Team.jsx" /* webpackChunkName: "component---src-templates-about-team-jsx" */),
  "component---src-templates-about-us-jsx": () => import("./../../../src/templates/AboutUs.jsx" /* webpackChunkName: "component---src-templates-about-us-jsx" */),
  "component---src-templates-about-why-china-jsx": () => import("./../../../src/templates/About/WhyChina.jsx" /* webpackChunkName: "component---src-templates-about-why-china-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/Blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/Contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-custom-page-jsx": () => import("./../../../src/templates/CustomPage.jsx" /* webpackChunkName: "component---src-templates-custom-page-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-investor-relations-assets-jsx": () => import("./../../../src/templates/InvestorRelations/Assets.jsx" /* webpackChunkName: "component---src-templates-investor-relations-assets-jsx" */),
  "component---src-templates-investor-relations-calendar-jsx": () => import("./../../../src/templates/InvestorRelations/Calendar.jsx" /* webpackChunkName: "component---src-templates-investor-relations-calendar-jsx" */),
  "component---src-templates-investor-relations-contact-jsx": () => import("./../../../src/templates/InvestorRelations/Contact.jsx" /* webpackChunkName: "component---src-templates-investor-relations-contact-jsx" */),
  "component---src-templates-investor-relations-esg-jsx": () => import("./../../../src/templates/InvestorRelations/ESG.jsx" /* webpackChunkName: "component---src-templates-investor-relations-esg-jsx" */),
  "component---src-templates-investor-relations-governance-advisor-jsx": () => import("./../../../src/templates/InvestorRelations/Governance/Advisor.jsx" /* webpackChunkName: "component---src-templates-investor-relations-governance-advisor-jsx" */),
  "component---src-templates-investor-relations-governance-board-jsx": () => import("./../../../src/templates/InvestorRelations/Governance/Board.jsx" /* webpackChunkName: "component---src-templates-investor-relations-governance-board-jsx" */),
  "component---src-templates-investor-relations-governance-index-jsx": () => import("./../../../src/templates/InvestorRelations/Governance/index.jsx" /* webpackChunkName: "component---src-templates-investor-relations-governance-index-jsx" */),
  "component---src-templates-investor-relations-governance-management-jsx": () => import("./../../../src/templates/InvestorRelations/Governance/Management.jsx" /* webpackChunkName: "component---src-templates-investor-relations-governance-management-jsx" */),
  "component---src-templates-investor-relations-governance-ownership-structure-jsx": () => import("./../../../src/templates/InvestorRelations/Governance/OwnershipStructure.jsx" /* webpackChunkName: "component---src-templates-investor-relations-governance-ownership-structure-jsx" */),
  "component---src-templates-investor-relations-governance-revisors-jsx": () => import("./../../../src/templates/InvestorRelations/Governance/Revisors.jsx" /* webpackChunkName: "component---src-templates-investor-relations-governance-revisors-jsx" */),
  "component---src-templates-investor-relations-governance-shareholder-meetings-jsx": () => import("./../../../src/templates/InvestorRelations/Governance/ShareholderMeetings.jsx" /* webpackChunkName: "component---src-templates-investor-relations-governance-shareholder-meetings-jsx" */),
  "component---src-templates-investor-relations-governance-statute-jsx": () => import("./../../../src/templates/InvestorRelations/Governance/Statute.jsx" /* webpackChunkName: "component---src-templates-investor-relations-governance-statute-jsx" */),
  "component---src-templates-investor-relations-index-jsx": () => import("./../../../src/templates/InvestorRelations/index.jsx" /* webpackChunkName: "component---src-templates-investor-relations-index-jsx" */),
  "component---src-templates-investor-relations-ipo-jsx": () => import("./../../../src/templates/InvestorRelations/IPO.jsx" /* webpackChunkName: "component---src-templates-investor-relations-ipo-jsx" */),
  "component---src-templates-investor-relations-news-jsx": () => import("./../../../src/templates/InvestorRelations/News.jsx" /* webpackChunkName: "component---src-templates-investor-relations-news-jsx" */),
  "component---src-templates-investor-relations-policy-for-operational-cost-jsx": () => import("./../../../src/templates/InvestorRelations/PolicyForOperationalCost.jsx" /* webpackChunkName: "component---src-templates-investor-relations-policy-for-operational-cost-jsx" */),
  "component---src-templates-investor-relations-preferred-stock-jsx": () => import("./../../../src/templates/InvestorRelations/PreferredStock.jsx" /* webpackChunkName: "component---src-templates-investor-relations-preferred-stock-jsx" */),
  "component---src-templates-investor-relations-redemption-clause-jsx": () => import("./../../../src/templates/InvestorRelations/RedemptionClause.jsx" /* webpackChunkName: "component---src-templates-investor-relations-redemption-clause-jsx" */),
  "component---src-templates-investor-relations-reports-presentations-jsx": () => import("./../../../src/templates/InvestorRelations/ReportsPresentations.jsx" /* webpackChunkName: "component---src-templates-investor-relations-reports-presentations-jsx" */),
  "component---src-templates-investor-relations-single-news-jsx": () => import("./../../../src/templates/InvestorRelations/SingleNews.jsx" /* webpackChunkName: "component---src-templates-investor-relations-single-news-jsx" */),
  "component---src-templates-portfolio-asset-value-jsx": () => import("./../../../src/templates/Portfolio/AssetValue.jsx" /* webpackChunkName: "component---src-templates-portfolio-asset-value-jsx" */),
  "component---src-templates-portfolio-portfolio-holdings-jsx": () => import("./../../../src/templates/Portfolio/PortfolioHoldings.jsx" /* webpackChunkName: "component---src-templates-portfolio-portfolio-holdings-jsx" */),
  "component---src-templates-pre-ipo-jsx": () => import("./../../../src/templates/PreIpo.jsx" /* webpackChunkName: "component---src-templates-pre-ipo-jsx" */),
  "component---src-templates-single-post-jsx": () => import("./../../../src/templates/SinglePost.jsx" /* webpackChunkName: "component---src-templates-single-post-jsx" */),
  "component---src-templates-tag-jsx": () => import("./../../../src/templates/Tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */)
}

